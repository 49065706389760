.ui-stackedbar {
  position: relative;
  right: 20px; }
  .ui-stackedbar .bars {
    width: 100%; }
    .ui-stackedbar .bars span {
      display: block;
      box-sizing: border-box;
      float: right;
      padding: 3px;
      margin: 0 -2px;
      border: 2px solid #fff;
      border-radius: 10px; }
  .ui-stackedbar ul.legend {
    display: flex;
    padding: 0.625rem 20px;
    color: #555;
    font-size: 0.6875rem; }
  .ui-stackedbar li {
    padding-right: 0.375rem; }
    .ui-stackedbar li .category {
      padding-left: 0.1875rem; }
    .ui-stackedbar li .fas {
      vertical-align: middle;
      font-size: 6px;
      margin-top: -2px; }
  .ui-stackedbar .clear {
    clear: both; }
  @media (min-width: 768px) {
    .ui-stackedbar ul.legend {
      font-size: 0.8125rem; }
    .ui-stackedbar li {
      padding-right: 0.5rem; }
      .ui-stackedbar li .fas {
        font-size: 7px; } }
